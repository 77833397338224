






















































































































































































































































/* eslint-disable max-len */
import { Component, Prop, Watch } from 'vue-property-decorator';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import { mixins } from 'vue-class-component';
import Order from '@/models/graphql/Order';
import { Module } from 'vuex-module-decorators';
import { namespace } from 'vuex-class';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import { Data } from '@/utils/types/WidgetData';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { getUnixTime } from 'date-fns';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import OrderPackage from '@/models/graphql/OrderPackage';
import FormatHelper from '@/utils/helpers/FormatHelper';
import OrderPaymentCharge from '@/models/graphql/OrderPaymentCharge';
import OrderStatus from '@/utils/enums/OrderStatus';

const orderStore = namespace('OrderStore');

@Component({
  computed: {
    FileResourceHelper() {
      return FileResourceHelper;
    },
  },
  components: {
    FontAwesomeComponent,
    ButtonComponent,
  },
})

@Module({ namespaced: true })
export default class OrderDetailWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  @Prop({ default: null })
  private readonly entityCode!: string;

  private dateLocale!: Locale;

  @Prop({ required: false, default: () => [] })
  private data!: Data[];

  @orderStore.Action
  private getPaymentIntentCharge!: (payload: { orderUid: string }) => Promise<OrderPaymentCharge>;

  private format = false;

  private orderStatus = OrderStatus;

  private paymentCharge: OrderPaymentCharge | null = null;

  private get orderDetails(): Order | null {
    if (this.data && this.data.length > 0) {
      return Order.hydrate(this.data[0]);
    }
    return null;
  }

  private get formatedCreationTime(): string {
    if (this.data) {
      return DateTimeHelper.formatFromUnixTime(
        getUnixTime(new Date(String(this.orderDetails?.creationTime))),
        `${this.$t('order.detail.time-format')}`,
        this.dateLocale,
      );
    }
    return '';
  }

  private get statusBackground(): string {
    if (this.orderDetails) {
      switch (this.orderDetails.status) {
        case 'PAID':
          return 'bg-green-g-1-light-green';
        default:
          return 'bg-yellow-y-1-light-yellow';
      }
    }
    return '';
  }

  private get getOrderStatus(): string {
    return this.$t(`sales.order.status.${this.orderDetails?.status}`).toString();
  }

  private get orderNumber(): string {
    return FormatHelper.formatOrderNumber(this.orderDetails?.id || 0);
  }

  created(): void {
    if (this.payload && 'entityCode' in this.payload && this.payload.entityCode) {
      const orderId = this.handleBaseUrl();
      this.setDataConfig([{
        gqlDefinition: buildQueryDefinition({
          filter: {
            value: { uid: orderId },
            type: GqlEntityFilterType.ORDER_FILTER,
          },
        }),
        operation: 'order',
        fragmentName: 'orderForSalesComponents',
        alias: this.storeName,
      }]);
    } else if (this.widget) {
      this.setDataConfig(
        undefined,
        true,
        `Missing orderCode in payload for ${this.widget.type} widget with id ${this.widget.id}`,
      );
    }
  }

  @Watch('data')
  setPaymentIntentCharge(): void {
    if (this.orderDetails?.paymentIntentId) {
      this.getPaymentIntentCharge({ orderUid: this.orderDetails.uid })
        .then((paymentCharge: OrderPaymentCharge) => {
          this.paymentCharge = paymentCharge;
        });
    }
  }

  onInvoiceClick(): void {
    const order = this.orderDetails;
    if (order) {
      const salesInvoiceUrl = this.$router.resolve({
        name: 'sales-invoice', params: { orderId: order.uid as string },
      }).href;
      window.open(salesInvoiceUrl, '_blank');
    }
  }

  private handleBaseUrl(): string {
    if (this.payload && this.payload.entityCode) {
      let r = String(this.payload.entityCode);
      const matches = String(this.payload.entityCode).match(/(%[a-zA-Z-_.[0-9\]*]+%)/gs);
      if (matches) {
        matches.forEach((m) => {
          const prop = m.replaceAll('%', '').trim();
          if (this.$route.params[prop]) {
            r = r.replaceAll(m, this.$route.params[prop]);
          }
        });
      }
      return r;
    }
    return '';
  }

  // eslint-disable-next-line class-methods-use-this
  private getSalesPackageImage(orderPackage: OrderPackage): string {
    if (!orderPackage.salesPackage?.images?.length) return '/img/Empty_cart_image.png';
    return FileResourceHelper.getFullPath(orderPackage.salesPackage.images[0]);
  }

  private formatCurrency(price?: number): string {
    this.format = true;
    return FormatHelper.formatCurrency((price || this.orderDetails?.totalPrice || 0) / 100);
  }
}
